import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers["Accept-Language"] = localStorage.language || "az";

axios.interceptors.response.use(
  (res) => {
    Controls(res);
    return res;
  },
  (err) => {
    Controls(err.response);
    return err.response;
  }
);

const Controls = (res) => {
  if (res) {
    const { status, messages } = res?.data;
    switch (status) {
      case 200: {
        if (messages) {
          // message.destroy('msg');
          // message.success(messages);
        }
        break;
      }
      case 201: {
        // message.destroy('msg');
        // message.success(messages);
        break;
      }
      case 400: {
        // message.destroy('msg');
        // message.error(messages);
        break;
      }
      case 401: {
        // message.destroy('msg');
        // message.error(messages);
        localStorage.clear();
        window.open(process.env.REACT_APP_LOGIN, "_parent");
        // alert(401);
        break;
      }
      case 403: {
        // message.destroy('msg');
        // message.error(messages);
        break;
      }
      case 404: {
        window.open(process.env.REACT_APP_404, "_parent");
        // alert(404);
        break;
      }
      case 500: {
        window.open(process.env.REACT_APP_500, "_parent");
        // alert(500);
        break;
      }

      default:
        break;
    }
  }
};

export default axios;
