import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import API from "../../API";

const ServicesCard = () => {
  const [services, setServices] = useState([]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllServices = () => {
    API.Services.index().then((res) => {
      setServices([...res?.data?.data?.data]);
    });
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <Slider {...settings}>
      {services &&
        services.map((service) => (
          <div className="flex flex-col" key={service._id}>
            <div className="serviceimage w-full h-52 mb-7">
              <Link to={`/service/${service?._id}`}>
                <img
                  className="w-full h-full object-fill"
                  src={`${process.env.REACT_APP_BASE_URL}${service?.image}`}
                  alt=""
                />
              </Link>
            </div>
            <Link to={`/service/${service?._id}`}>
              <h2 className="text-white font-bold text-xl hover:text-amber-400 px-4">
                {service?.title}
              </h2>
            </Link>
          </div>
        ))}
    </Slider>
  );
};

export default ServicesCard;
