import Products from "./Products";
import ProductCategories from "./ProductCategories";
import ProductSubCategories from "./ProductSubCategories";
import Services from "./Services";
import ServicCategories from "./ServicCategories";
import Slider from "./Slider";
import Partners from "./Partners";
import Setting from "./Setting";
import ContactUs from "./ContactUs";
import Blogs from "./Blogs";

export default {
  Products,
  ProductCategories,
  ProductSubCategories,
  Services,
  ServicCategories,
  Slider,
  Partners,
  Setting,
  ContactUs,
  Blogs,
};
